import * as React from "react";
import Layout from "../components/layout";

// markup
const PrivacyNoticePage = () => {
  return (
    <Layout pageTitle="Privacy Notice">
      <div className="mx-auto flex flex-col max-w-5xl overflow-scroll pb-9">
        <div className="flex flex-col my-5">
          <h1 className="mx-auto">Privacy Notice</h1>
          <h2 className="mx-auto"> Last revised: September 2022</h2>
        </div>

        <h1 className="mt-6">1. An overview of data protection</h1>
        <p>
          We are committed to ensure the highest standards of data protection in
          compliance with the applicable legislation, notably with reference to
          the Regulation (EU) 2016/679 of the European Parliament and of the
          Council of 27 April 2016 on the protection of natural persons with
          regard to the processing of personal data and on the free movement of
          such data (the &ldquo;GDPR&rdquo;).
        </p>
        <p>
          In this document we detail the personal data we collect about You when
          You use our web site, the reason why we use your data, share your data
          and for how long we will keep it. Additionally, the notice also
          provides You with information regarding your rights, how to exercise
          them and whom You can contact in case of any query.
        </p>
        <h1 className="mt-6">2. Scope of the notice</h1>
        <p>
          The present notice is directed to users or visitors of this website,
          that is, You.
        </p>
        <h1 className="mt-6">3. Identity of the data controller</h1>
        <p>
          The data controller, that is, the entity responsible for collecting
          and processing your personal data, is the Luxembourg Institute of
          Science and Technology (LIST):
        </p>
        <table>
          <tbody>
            <tr>
              <td width="85">
                <p>Address:</p>
              </td>
              <td width="516">
                <p>5, avenue des Hauts-Fourneaux</p>
                <p>L-4362 Esch-sur-Alzette</p>
                <p>Luxembourg</p>
              </td>
            </tr>
            <tr>
              <td width="85">
                <p>E-mail:</p>
              </td>
              <td width="516">
                <p>
                  <a href="mailto:dpo@list.lu">dpo@list.lu</a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <h1 className="mt-6">4. Categories of personal data we collect</h1>
        <ol>
          <li className="my-4">a) Contact form</li>
        </ol>
        <p>
          You may voluntarily enter in contact with us by using the contact form
          available on the website. We collect your name and email. We need this
          information with the purpose of contacting you back and answer your
          requests. We will keep this data for the duration of the project and a
          XX years of retention in accordance with LIST retention schedule.
        </p>
        <ol>
          <li className="my-4">b) Self-assessment tool</li>
        </ol>
        <p>
          The answers you provide when using the self-assessment tool are purely
          processed to provide you with final recommendations in the field of
          Connected and Autonomous Vehicles. This information is immediately
          deleted from our systems once the recommendation are formulated by the
          tool; in case you close the browser page before submitting your
          questionnaire, your answers will be temporarily saved in case you wish
          to resume the test. In any case, this information is then deleted on a
          daily and automatic basis.
        </p>
        <p>
          When you complete the questionnaire, we will also have access to your
          IP address and technical logs related to your visit. However, please
          note that these data will be retained for a duration of 14 days and
          will only be accessible to LIST&rsquo;s research team.
        </p>
        <ol>
          <li className="my-4">c) Session Cookies</li>
        </ol>
        <p>
          Cookies are small text files that are sent and stored to your personal
          computer or device when you visit a website. The information stored
          may be returned to the website&rsquo;s servers or to the servers of
          the relevant third parties during a subsequent visit.
        </p>
        <p>
          This website uses session cookies. These cookies are used to enable
          you to visit the website and its features and they are automatically
          deleted once the navigation browser is closed. Users&rsquo; consent is
          not required for session cookies.
        </p>
        <ol>
          <li className="my-4">
            d) Technical data (including your IP address, technical logs)
          </li>
        </ol>
        <p>
          The temporary storage of IP addresses is necessary to enable the
          server information to be delivered to the user's computer/device. For
          this, the IP address of the user must be stored for the duration of
          the session. The data are not evaluated for marketing purposes.
        </p>
        <p>
          Technical log files are stored for 14 days to ensure the functionality
          of the system. In addition, the data serves us to optimise our tool
          and to ensure the security of our information technology systems.
        </p>
        <h1 className="mt-6">5. Purpose and legal basis for processing</h1>
        <p>
          Please find here below a list of the legal basis on whose grounds LIST
          collects and processes your personal data:
        </p>
        <ul>
          <li className="my-4">
            Your consent, which is requested before using the self-assessment
            tool presented on this website;
          </li>
          <li className="my-4">
            LIST&rsquo;s legitimate interest, which consists of replying to
            questions and queries, managing our and improving the present
            website and establishing and defending our legal rights.
          </li>
        </ul>
        <h1 className="mt-6">
          6. Share of your personal data with third parties
        </h1>
        <p>
          Your personal data, collected via the contact form of this website,
          may be shared among project partners for the duration of the project.
          You can see the details of the partners at the following link:{" "}
          <a href="https://pascal-project.eu/partners">
            https://pascal-project.eu/partners
          </a>
          .
        </p>
        <p>
          More specifically, please note that the current website is managed by
          Inetum, a partner within PAsCAL project that does not have access to
          any data related to the self-assessment tool.
        </p>
        <h1 className="mt-6">
          7. Ensuring personal data security and integrity
        </h1>
        <p>
          In compliance with the applicable data protection legislation, LIST
          has put in place appropriate technical and organisational measures in
          order to prevent or act upon any unauthorised and unlawful processing
          or disclosure, accidental loss, modification or destruction of
          personal data. These measures are implemented based on the current
          state of art, an evaluation of the risks derived by the processing
          activity and the need to protect personal data. Such technical and
          organisation measures are regularly updated and/or adjusted to new
          technical developments or any organisational change that may affect
          LIST.
        </p>
        <h1 className="mt-6">8. Your rights and how to exercise them</h1>
        <p>
          With regards to your personal data collected and processed by LIST,
          you may exercise at any time the following rights:
        </p>
        <ul>
          <li className="my-4">
            <strong>- Right to access</strong>: You have the right to receive
            confirmation about whether or not your personal data is being
            processed by LIST. If that is the case, you have the right to know
            what data is being collected and processed and to obtain of copy of
            it;
          </li>
          <li className="my-4">
            <strong>- Right to rectification</strong>: If the personal data we
            hold about you is inaccurate or incomplete, you have the right to
            request to have it rectified;
          </li>
          <li className="my-4">
            <strong>- Right to erasure</strong>: Subject to certain conditions
            specified in art. 17 of the GDPR, you have the right to have your
            personal data deleted by LIST;
          </li>
          <li className="my-4">
            <strong>- Right to restriction of processing</strong>: Subject to
            certain conditions specified in art. 18 of the GDPR, you have the
            right to obtain restriction of the processing of your personal data
            performed by LIST;
          </li>
          <li className="my-4">
            <strong>- Right to data portability</strong>: Subject to certain
            conditions specified in art. 20 of the GDPR, you have the right to
            obtain a copy of the personal data you provided to LIST in in a
            structured, commonly used and machine-readable format and to request
            the transfer of these data to another data controller;
          </li>
          <li className="my-4">
            <strong>- Right to object</strong>: You have the right to object the
            processing of your personal data when the conditions set out in art.
            21 of the GDPR apply;
          </li>
          <li className="my-4">
            <strong>- Right to withdraw consent</strong>: If LIST is processing
            your personal data based on your consent, you have the right to
            withdraw that consent at any time. The withdrawal of such consent
            shall not affect the lawfulness of processing based on consent
            before its withdrawal.
          </li>
          <li className="my-4">
            <strong>
              Right to lodge a complaint with a supervisory authority
            </strong>
            : You have the right to lodge a complaint with the Commission
            Nationale pour la Protection des Donn&eacute;es (CNPD). More
            information on how to lodge a complaint are available on
            CNPD&rsquo;s website:{" "}
            <a href="https://cnpd.public.lu/">https://cnpd.public.lu</a>.
          </li>
        </ul>
        <p>
          You may exercise any of these rights by contacting our Data Protection
          Officer (DPO):
        </p>
        <ul>
          <li className="my-4">
            by e-mail at the following address:{" "}
            <a href="mailto:dpo@list.lu">dpo@list.lu</a>,
          </li>
          <li className="my-4">or by post at:</li>
        </ul>
        <p>Luxembourg Institute of Science and Technology</p>
        <p>Attn. Data Protection Officer</p>
        <p>5, Avenue des Hauts-Fourneaux</p>
        <p>L-4362 Esch-sur-Alzette, Luxembourg.</p>
        <br />
        <p>
          Please kindly note that your rights are not absolute and they may be
          withheld in accordance with applicable data protection laws. In such
          event, LIST will provide you with the reasons for not complying with
          your request. In such case, you may lodge a complaint with the CNPD
          and seek a judicial remedy against such decision.
        </p>
        <h1 className="mt-6">9. Link to other websites</h1>
        <p>
          Please be aware that this website may contain links to other website
          that are not governed by this privacy notice. We encourage You to
          review the privacy notice of each website before disclosing any
          personal data.
        </p>
        <h1 className="mt-6">10. Changes to this notice</h1>
        <p>
          LIST may make changes to this privacy notice from time to time, to
          reflect our current privacy practices or to comply with changes in the
          applicable data protection legislation. LIST encourages You to
          regularly visit this page in order to remain informed on how we
          collect and process personal data.
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyNoticePage;
